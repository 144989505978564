export class CheckBoxHandler {

  constructor() {
    this._handlers = {
      "disable": this.disable,
    }
  }

  init($container) {

    if (!$container) {
      $container = $(document);
    }

    $container.find('[data-checkbox-handler]').change((event) => {
      this.applyHandler($(event.currentTarget), $container);
    });

    $container.find('[data-checkbox-handler]').each((index, element) => {
      this.applyHandler($(element), $container);
    });
  }

  applyHandler($element, $container) {
    const handlerName = $element.data("checkbox-handler");
    const isChecked = $element.is(':checked');
    const id = $element.attr("id");

    if (this._handlers[handlerName]) {
      this._handlers[handlerName](id, isChecked, $container);
    }
  }

  disable(id, isChecked, $container) {
    if (isChecked) {
      $container.find(`[data-checkbox-disable-unchecked=${id}]`).removeAttr('disabled');
      $container.find(`[data-checkbox-disable-checked=${id}]`).attr('disabled', 'disabled');
    } else {
      $container.find(`[data-checkbox-disable-checked=${id}]`).removeAttr('disabled');
      $container.find(`[data-checkbox-disable-unchecked=${id}]`).attr('disabled', 'disabled');
    }
  }
}
