export class StatsWorkflow {

  constructor() {

    $('[data-switch]').bootstrapSwitch({
      onColor: 'success',
      onText: '<i class="fa fa-check"></i>',
      offText: '<i class="fa fa-close"></i>',
      onSwitchChange: (event, state) => {
        document.location.href = $(event.currentTarget).data("url");
      }
    });
  }

}
