export class DateTimePicker {

  constructor(format, {autocomplete = false, minView = null, changeDateCallback = null} = {}) {
    this.$datetimepicker = $('[data-datetime-picker]');
    this.format = format || 'dd/mm/yyyy hh:ii';
    this.autocomplete = autocomplete === true;
    this.changeDateCallback = changeDateCallback;
    this.minView = minView;

    this.init();
  }

  init() {
    this.$datetimepicker.datetimepicker({
      format: this.format,
      language: 'fr',
      minView: this.minView,
    }).on("changeDate", (event) => {
      this.onChangeDate(event);
    });

    if (!this.autocomplete) {
      this.$datetimepicker.attr('autocomplete', 'off');
    }
  }

  onChangeDate(event) {
    if (this.changeDateCallback && typeof this.changeDateCallback === 'function') {
      this.changeDateCallback(event);
    }
  }

}
