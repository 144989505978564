import {modal} from "./Modal";
import {AjaxFormWorkflow} from "./AjaxFormWorkflow";
import {CheckBoxHandler} from "./CheckBoxHandler";
import {Select2Handler} from "./Select2Handler";

export class ModalLoader {

  constructor(selector, options) {
    this.$fields = $(selector || '[data-modal-loader]');
    this.options = options || {};

    this.initEvents();
  }

  initEvents() {
    this.$fields.click((event) => {
      event.preventDefault();

      const $currentTarget = $(event.currentTarget);
      const url = $currentTarget.attr('href') || $currentTarget.attr('data-url') || "";
      const loader = $currentTarget.data('modal-loader');

      modal.load(url, $modal => {

        new CheckBoxHandler().init($modal);
        new Select2Handler($modal).init();

        $modal.filter('.modal').first().modal('show');

        const loaderOptions = {
          enableAjax: !this.options[loader] || this.options[loader]['handleAjaxFormSubmit'] !== false,
          onLoadedAvailable: this.options[loader] && this.options[loader]['onLoaded'] && typeof this.options[loader]['onLoaded'] === 'function',
        };

        $modal.find('form').each((index, element) => {
          const $element = $(element);
          const formId = $element.attr("id");

          if (formId && loaderOptions.enableAjax && $element.attr("data-handle-ajax-form") !== "false") {
            new AjaxFormWorkflow(formId);
          }
        });

        if (loaderOptions.onLoadedAvailable) {
          this.options[loader]['onLoaded']($modal);
        }
      });
    });
  }

}
