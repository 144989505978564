import EventClass from "./EventClass";

class Loading extends EventClass {

  constructor() {
    super();
    $('[data-loading]').click(event => this.onLoadingClick(event));
  }

  show(loader) {
    $(`[data-hide-on-loading=${loader}]`).hide();
    $(`[data-show-on-loading=${loader}]`).show();
    $(`[data-loader=${loader}]`).show();
    this.emit('loading', loader);
  }

  hide(loader) {
    $(`[data-hide-on-loading=${loader}]`).show();
    $(`[data-show-on-loading=${loader}]`).hide();
    $(`[data-loader=${loader}]`).hide();
    this.emit('loaded', loader);
  }

  onLoadingClick(event) {
    let $currentTarget = $(event.currentTarget);
    if ($currentTarget.attr('disabled') !== 'disabled' || $currentTarget.data("loading-force") === "true") {
      loader.show($currentTarget.data('loading'));
    }
  }
}

const loader = new Loading();

export {loader};
