import {loader} from "./Loading";

export class AjaxFormWorkflow {
  constructor(formId, {onFormSuccessCallback = null, onFormErrorCallback = null, beforeSubmitCallback = null, onProgressCallback = null} = {}, light = true) {
    this.$form = $(`form#${formId}`);
    this.formId = formId;

    this.onFormSuccessCallback = onFormSuccessCallback;
    this.onFormErrorCallback = onFormErrorCallback;
    this.beforeSubmitCallback = beforeSubmitCallback;
    this.onProgressCallback = onProgressCallback;

    const ajaxFormOptions = {
      onResponse: (success, data) => {

        if (success) {
          this.onFormSuccess(data);
        } else {
          this.hideLoader();
          this.onFormError(data);
        }
      },
      beforeSubmit: ($form) => {
        this.showLoader();
        this.beforeSubmit($form);
      },
      onProgress: (progress) => {
        this.onProgress(progress);
      },
    };

    if (light !== true) {
      this.$form.handleAjaxSubmit(ajaxFormOptions);
    } else {
      this.$form.handleLightAjaxSubmit(ajaxFormOptions);
    }

    this.initForm();

  }

  initForm() {
    // to override
  }

  hideLoader() {
    loader.hide(`${this.formId}-submit`);
  }

  showLoader() {
    loader.show(`${this.formId}-submit`);
  }

  onFormSuccess(data) {
    if (this.onFormSuccessCallback && typeof this.onFormSuccessCallback === 'function') {
      this.onFormSuccessCallback(data);
    }
  }

  onFormError(data) {
    if (this.onFormErrorCallback && typeof this.onFormErrorCallback === 'function') {
      this.onFormErrorCallback(data);
    }
  }

  beforeSubmit($form) {
    if (this.beforeSubmitCallback && typeof this.beforeSubmitCallback === 'function') {
      this.beforeSubmitCallback($form);
    }
  }

  onProgress(progress) {
    if (this.onProgressCallback && typeof this.onProgressCallback === 'function') {
      return this.onProgressCallback(progress);
    }
  }

  get ajaxForm() {
    return this.$form.data('ajaxForm') || this.$form.data('lightAjaxForm');
  }
}
