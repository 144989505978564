import EventClass from "./EventClass";

export class Select2Handler extends EventClass {

  constructor($container, params = {}) {
    super();
    this.$container = $container || $(document);
    this.params = params;
  }

  load(url) {
    this.emit("loading");
    document.location.href = url;
  }

  init() {

    if (this.$elements.length > 0) {
      this.destroy();
    }

    $("select[data-select-url]").change($.debounce(1000, (event) => {
      const $currentTarget = $(event.currentTarget);

      if ($currentTarget.attr("multiple")) {
        const baseUrl = $currentTarget.data("select-url");
        let values = [];

        $currentTarget.find("option:selected").each((i, option) => {
          const $option = $(option);
          values.push($option.attr("value"));
        });

        this.load(baseUrl.replace("__value__", values.join("|")));
      } else {
        this.load($currentTarget.val());
      }
    }));

    this.$container.find('[data-select2="search"] select').each((index, element) => {
      const $element = $(element);
      const options = this.getOptions($element);
      $element.select2({
        width: "100%",
        closeOnSelect: options.closeOnSelect,
        allowClear: options.allowClear,
        placeholder: options.placeholder,
      });
    });

    this.$container.find('[data-select2="nosearch"] select').each((index, element) => {
      const $element = $(element);
      const options = this.getOptions($element);
      $element.select2({
        width: "100%",
        minimumResultsForSearch: Infinity,
        closeOnSelect: options.closeOnSelect,
        allowClear: options.allowClear,
        placeholder: options.placeholder,
      });
    });

    this.$container.find('[data-select2="ajax"] select').each((index, element) => {
      const $element = $(element);
      const options = this.getOptions($element);
      const url = $element.data("select2-url");
      const id = $element.data("select2-id");

      if (url) {
        $element.select2({
          width: "100%",
          closeOnSelect: options.closeOnSelect,
          allowClear: options.allowClear,
          placeholder: options.placeholder,
          ajax: {
            url: url,
            delay: $element.data("select2-delay") || 0,
            dataType: 'json',
            data: (params) => {
              return {
                search: params.term,
                page: params.page
              };
            },
            processResults: (data) => {

              if (id && this.params[`processResults_${id}`] && typeof this.params[`processResults_${id}`] === 'function') {
                return this.params[`processResults_${id}`](data);
              }

              return {
                results: $.map(data.results, (val) => {
                  return {
                    "id": val.id,
                    "text": val.text,
                  }
                }),
                pagination: data.pagination,
              }
            }
          },
        });
      }
    });
  }

  destroy() {
    this.$elements.select2("destroy");
  }

  getOptions($element) {
    return {
      closeOnSelect: $element.data("select2-close-on-select") !== false,
      allowClear: $element.data("select2-allow-clear") === true,
      placeholder: $element.data("select2-placeholder") || $element.attr("multiple") ? null : '---------',
    };
  }

  get $elements() {
    return this.$container.find('.select2-hidden-accessible');
  }

}
