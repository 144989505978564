import {AjaxFormWorkflow} from "./AjaxFormWorkflow";

export class ModelWorkflow {

  constructor() {
    this.$form = null;
    this.ajaxForm = null;
  }

  initForm(formId, options = {}) {
    if (formId) {
      this.$form = $(`form#${formId}`);
      this.ajaxForm = new AjaxFormWorkflow(formId, options, options.light !== false);
    }
  }

  edit(formId, options = {}) {
    this.initForm(formId, options);
  }

  create(formId, options = {}) {
    this.initForm(formId, options);
  }

  list(options = {}) {
    // to override
  }
}
